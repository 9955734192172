const mq = {
  a375: `@media(min-width: 375px)`,
  a500: `@media(min-width: 500px)`,
  a768: `@media(min-width: 768px)`,
  a992: `@media(min-width: 992px)`,
  a1200: `@media(min-width: 1200px)`,
  a1400: `@media(min-width: 1400px)`,
  a1600: `@media(min-width: 1600px)`,
  a1800: `@media(min-width: 1800px)`,
  a2200: `@media(min-width: 2200px)`,
};

export default mq;
