import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import "./layout.css";
import "./all.sass";
import BulmaFooter from "./BulmaFooter";
import Navbar from "./Navbar";
import BulmaNavbar from "./BulmaNavbar";

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <html lang="en" />
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
          {/*<link*/}
          {/*  rel="apple-touch-icon"*/}
          {/*  sizes="180x180"*/}
          {/*  href="/img/apple-touch-icon.png"*/}
          {/*/>*/}
          {/*<link*/}
          {/*  rel="icon"*/}
          {/*  type="image/png"*/}
          {/*  href="/img/favicon-32x32.png"*/}
          {/*  sizes="32x32"*/}
          {/*/>*/}
          {/*<link*/}
          {/*  rel="icon"*/}
          {/*  type="image/png"*/}
          {/*  href="/img/favicon-16x16.png"*/}
          {/*  sizes="16x16"*/}
          {/*/>*/}

          {/*<link*/}
          {/*  rel="mask-icon"*/}
          {/*  href="/img/safari-pinned-tab.svg"*/}
          {/*  color="#ff4400"*/}
          {/*/>*/}
          <link rel="icon" href="/img/octhh_favicon.ico" />

          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:image" content="/img/octhh_web_home.png" />
        </Helmet>
        <Navbar />
        <BulmaNavbar />
        <main>{children}</main>
        {/*<Footer />*/}
        <BulmaFooter />
      </>
    )}
  />
);

export default TemplateWrapper;
